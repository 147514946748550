body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App header {
  margin-bottom: 2rem;
}

.toolbar a,
.toolbar a:visited,
.toolbar a:active {
  color: #ffffff;
  text-decoration: none;
  margin: 0 2rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButton-contained {
  position: relative;
  top: 10px
}

.cert_title {
  font-size: 24px;
}

.cert_verify {
  font-size: 12px;
}

.cert_border_out {
  margin: auto;
  width: 800px;
  height: 600px;
  padding: 20px;
  text-align: center;
  border: 10px solid #787878
}

.cert_border_in {
  width: 750px;
  height: 550px;
  padding: 20px;
  text-align: center;
  border: 5px solid #787878
}

.app-header {
  margin-top: 3rem;
  margin-bottom: 3rem;
}